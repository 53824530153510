export const environment = {
  production: true,
  baseApiUrl: 'https://api.swiftterm-dev.com/dev',
  paymentServiceBaseApiUrl: 'https://swiftcore.swiftterm-dev.com/dev',
  useMocks: true,
  stripePublishableKey: 'pk_test_51HxJbUDsqtS0AS1udSWahsjreOi1g5D7PH7xHztavJB7SqTKMRtqsjtoqO9auyHGowi2tnSy3cUYj0MxIHIiPTo900hZILXt7P',
  signupUrl: 'https://auth.swiftterm-dev.com/signup?client_id=151b693gvulq138ffddm8smmn4&response_type=token&scope=email+openid&redirect_uri=https://www.swiftterm-dev.com/callback',
  loginUrl: 'https://auth.swiftterm-dev.com/login?client_id=151b693gvulq138ffddm8smmn4&response_type=token&scope=email+openid&redirect_uri=https://www.swiftterm-dev.com/callback',
  logoutUrl: 'https://auth.swiftterm-dev.com/logout?client_id=151b693gvulq138ffddm8smmn4&response_type=token&scope=email+openid&logout_uri=https://www.swiftterm-dev.com/signout',
  googleTagManagerID: 'GTM-MDB2SKZ',
  googleTagManagerEnv: 'env-17',
  googleTagManagerAuth: 'wiRqVRJg0EG0-9jMV7ZYBg',
  senderrors: true,
  amazonConnectUrl: 'https://zrfj6d3mwg.execute-api.us-west-2.amazonaws.com/Prod',
  amazonConnectContactFlowId: '400f8d53-d30c-4320-866e-3f54186683a1',
  amazonConnectInstanceId: '0373a6bb-5c04-4dcb-84ad-ff5002d68645',
  amazonConnectRegion: 'us-west-2',
  googleMapsApiKey: 'AIzaSyAOLX23yd1ecDjqRTqFTek6VEet5dJVYpc',
  googleAuthClientId: '753069674880-1u45jlclvcr1eu7rf0cb5imevt6lge5v.apps.googleusercontent.com',
  plaidClientId: '60556326a37361000f84aef6',
  plaidEnvironment: 'sandbox',
  creditCardThreshold: 500000,
  stripeProductId: 'prod_InRe2IGOLZCSEN',
  // Branch 'nichaz/bene-update' contains correct mapping for create beneficiary when this is enabled
  beneficiariesEditable: false,
  allowDraftDateChange: false,
  allowSecondaryAddressChange: true,
  allowContactEdit: false,
  baseCmsUrl: 'https://prodcms-www.symetra.com',
  contactFormEndpoint: 'https://symetrafsc--dev.my.salesforce.com/servlet/servlet.WebToCase?encoding=UTF-8',
  salesforceDebug: true,
  salesforceDebugEmail: 'automated0tester0sym@gmail.com',
  salesforceOrgId: '00D4C0000008yhH',
  contactFormHoneypotFieldKey: '00N4C000001SObJ',
  contactFormReverseHoneypotFieldKey: '00N4C000001SObK',
  claimsContact: '1-833-447-9438',
  buildId: '20241226.5',
  contractMaxRetries: 8,
  contractRetryIntervalMs: 8000,
  allowDeclineReminders: false,
  handleBankMicroTransactions: true,
  showMilestoneBlock: false
};
